import { updateGameState,playerDisconnected,newMessageReceived,newBetUpdate,updateAllPlayerCards, playerJoined,updatePlayerBet,handleSetWinner, updateMyInfo, updatePlayersList, gameStarting ,updatePlayerCards,setNextPlayer,updateCommunityCards} from '../redux/gameSlice';
import io from 'socket.io-client';

let socket;

export const connectToGame = (gameId, dispatch) => {
  const token = localStorage.getItem('token');
  if (!token) {
    console.error('No token found in localStorage');
    return;
  }

  const baseURL = process.env.REACT_APP_API_BASE_URL;

  socket = io(`${baseURL}/game`, {
    auth: {
      token,
    },
  });

  // When connected to the socket
  socket.on('connect', () => {
    socket.emit('join_game', { game_id: gameId });
  });

  // Receiving the full game information including players array
  socket.on('game_info', (gameInfo) => {
    dispatch(updateGameState(gameInfo));
  });

  // Receiving the list of players already in the game
  socket.on('players_in_game', (playersList) => {
    console.log("players in gmae", playersList)
    if (playersList && Array.isArray(playersList.players)) {
      dispatch(updatePlayersList(playersList.players));
    } else {
      console.error("Expected an array of players but got:", playersList);
    }
  });

  // Game is starting event
  socket.on('game_starting', ({ dealer_position, small_blind, big_blind }) => {
    dispatch(gameStarting({ dealer_position,small_blind,big_blind, }));
  });

  // New player joins the game
  socket.on('player_joined', ({ playerData }) => {
    if (playerData && playerData.username) {
      dispatch(playerJoined(playerData));
    } else {
      console.error("Invalid player data received:", playerData);
    }
  });

  // Receiving the user's own info
  socket.on('my_info', (myInfo) => {
    dispatch(updateMyInfo(myInfo));
  });

  socket.on('player_cards_info', ({ potValue, playerCards }) => {
    dispatch(updatePlayerCards({ potValue, playerCards }));

  });

  socket.on('player_bet_placed', ({ details}) => {
    dispatch(updatePlayerBet({ details }));

  });

  socket.on('new_bet_placed', ({ details }) => {
    dispatch(newBetUpdate({ details }))
  });

  socket.on('user_disconnected', ({ player_id }) => {
    dispatch(playerDisconnected({ player_id }));
  });

  socket.on('winner_declared', ({ winner}) => {
    dispatch(handleSetWinner(winner));
  });
  socket.on('all_players_cards', ({ all_players_cards}) => {
    dispatch(updateAllPlayerCards(all_players_cards));

  });

  socket.on('community_cards', ({ community_cards}) => {
    dispatch(updateCommunityCards(community_cards));
  });

  socket.on('next_player', ({ details }) => {
      dispatch(setNextPlayer(details)); 

    });

  socket.on('disconnect', () => {
  });

  socket.on('error', (error) => {
    console.error('WebSocket Error: ', error);
  });

  socket.on('new_message', ({ player_id, message,  }) => {
    dispatch(newMessageReceived({ player_id, message })); 
  });
};

// Emit start game event
export const startGame = (gameId) => {
  if (socket) {
    socket.emit('start_game', { game_id: gameId });
  } else {
    console.error("WebSocket not connected.");
  }
};

export const placeBet = (gameId, playerId, amount, betType) => {
  if (socket) {
    socket.emit('bet_placed', { game_id: gameId, player_id: playerId, amount, bet_type: betType });
  } else {
    console.error("WebSocket not connected.");
  }
};



export const getPlayersCards = (gameId) => {
  if (socket) {
    socket.emit('get_player_cards', { game_id: gameId });
  } else {
    console.error("WebSocket not connected.");
  }
};

export const sendMessage = (gameId, message) => {
  if (socket) {
    socket.emit('send_message', { game_id: gameId, message });
  } else {
    console.error("WebSocket not connected.");
  }
};



