import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../../components/header/Header';
import api from '../../api/axiosInstane';

// Import images for hand combinations
import royalFlushImg from '../../assets/images/royal_flush.png';
import straightFlushImg from '../../assets/images/straight_flush.png';
import fourOfAKindImg from '../../assets/images/four_of_a_kind.png';
import fullHouseImg from '../../assets/images/full_house.png';
import flushImg from '../../assets/images/flush.png';
import straightImg from '../../assets/images/straight.png';
import threeOfAKindImg from '../../assets/images/three_of_a_kind.png';
import twoPairImg from '../../assets/images/two_pair.png';
import onePairImg from '../../assets/images/one_pair.png';
import highCardImg from '../../assets/images/high_card.png';

const images = {
    'Royal Flush': royalFlushImg,
    'Straight Flush': straightFlushImg,
    'Four of a Kind': fourOfAKindImg,
    'Full House': fullHouseImg,
    'Flush': flushImg,
    'Straight': straightImg,
    'Three of a Kind': threeOfAKindImg,
    'Two Pair': twoPairImg,
    'One Pair': onePairImg,
    'High Card': highCardImg,
};

const PaymentPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const combination = location.state?.combination;

    // Ensure the combination data is available
    if (!combination) {
        return (
            <div className="min-h-screen flex items-center justify-center bg-gray-100">
                <p className="text-lg text-gray-700">No payment data available.</p>
            </div>
        );
    }

    const handleCreateGame = async () => {
        if (combination) {
            try {
                const token = localStorage.getItem('token');
                const response = await api.post('/api/games/create', 
                    {
                        game_name: combination.name,
                        total_players: 4,
                        amount: combination.price, 
                        is_paid: true,
                    }, 
                    {
                        headers: { 'Authorization': `Bearer ${token}` }
                    }
                );

                if (response.status === 201) {
                    alert('Game created successfully!');
                    navigate(`/game/${response.data.game_id}`);
                } else {
                    alert('Failed to create the game.');
                }
            } catch (error) {
                console.error('Error creating the game:', error);
                alert('Error creating the game. Please try again.');
            }
        } else {
            alert('Please select a hand combination.');
        }
    };

    return (
        <div className="flex flex-col min-h-screen bg-gray-100">
            <Header />
            <PayPalScriptProvider options={{ "client-id": "ARrxPHCtRnKWS9VJiVoECd0VOs7L-Wz0BFs6o0NNxkdcZKkXrHo9Kmuij6QFkGlWwKY1kCxBBS1ly7TF" }}>
                <div className="flex flex-col md:flex-row flex-1 p-8 space-y-6 md:space-y-0 md:space-x-6">
                    {/* Left Side: Game Details */}
                    <div className="flex-1 bg-white p-6 rounded-lg shadow-lg flex flex-col items-center text-center">
                        <img
                            src={images[combination.name] || images['High Card']}
                            alt={combination.name}
                            className="w-40 h-40 mb-4 object-contain"
                        />
                        <h1 className="text-2xl font-bold mb-2 text-gray-800">Payment for {combination.name}</h1>
                        <p className="text-lg text-gray-700 mb-4">You are about to pay <strong className="text-green-600">${combination.price}</strong> for the <strong>{combination.name}</strong> hand combination.</p>
                    </div>

                    {/* Right Side: Payment Options */}
                    <div className="flex-1 bg-white p-6 rounded-lg shadow-lg flex flex-col items-center text-center">
                        <h2 className="text-xl font-bold mb-4 text-gray-800">Select Payment Method</h2>
                        <div className="w-full flex justify-center mb-6">
                            <PayPalButtons
                                createOrder={(data, actions) => {
                                    return actions.order.create({
                                        purchase_units: [{
                                            amount: {
                                                value: combination.price,
                                            },
                                        }],
                                    });
                                }}
                                onApprove={async (data, actions) => {
                                    try {
                                        const details = await actions.order.capture();
                                        alert(`Transaction completed by ${details.payer.name.given_name}`);
                                        // After successful payment, create the game
                                        await handleCreateGame();
                                    } catch (err) {
                                        console.error(err);
                                        alert('An error occurred during the transaction. Please try again.');
                                    }
                                }}
                                onError={(err) => {
                                    console.error(err);
                                    alert('An error occurred during the transaction. Please try again.');
                                }}
                            />
                        </div>
                        <div className="text-center">
                            <p className="text-lg font-semibold mb-2 text-gray-800">Other Payment Methods Coming Soon</p>
                            <p className="text-sm text-gray-600">Currently, only PayPal is available. Stay tuned for more payment options like Neosurf and Credit Card.</p>
                        </div>
                    </div>
                </div>
            </PayPalScriptProvider>
        </div>
    );
};

export default PaymentPage;
